<template>
  <view class="goods_detail">
    <!-- 顶部导航 -->
    <!-- <top-nav></top-nav> -->
    <!-- 商品基本信息 -->
    <goods-info></goods-info>
    <!-- 商品规格 -->
    <goods-sku></goods-sku>
    <!-- 地址信息 -->
    <address-info></address-info>
    <!-- 商品详情 -->
    <more-detail></more-detail>
    <!-- 商品评论 -->
    <goods-comment></goods-comment>
    <!-- 底部按钮 -->
    <detail-footer></detail-footer>
  </view>
</template>

<script>
// import topNav from '@/components/buy/topNav.vue'
import moreDetail from '@/components/buy/moreDetail.vue'
import goodsInfo from '@/components/buy/goodsInfo.vue'
// import goodsComment from '@/components/buy/goodsComment.vue'
import detailFooter from '@/components/buy/detailFooter.vue'
import goodsSku from '@/components/buy/goodsSku.vue'
import addressInfo from '@/components/buy/addressInfo.vue'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // This ensures that the component is loaded before the route enters
      vm.$router.replace(vm.$route.fullPath); // This forces a refresh
    });
  },
  name: 'DetailPage',
  components: {
    // "top-nav": topNav,
    "more-detail": moreDetail,
    "goods-info": goodsInfo,
    // "goods-comment": goodsComment,
    "detail-footer": detailFooter,
    "goods-sku": goodsSku,
    "address-info": addressInfo
  }
  }
</script>

<style lang="scss">
.goods_detail {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  z-index: 100;
  padding-bottom: 70px;
  .nut-ellipsis {
    flex: 1;
    margin-left: 4px;
    min-width: 200px;
  }
}

</style>