<template>
  <div class="address_info">
    <div class="selected_info" @click="showAddressExist">
      <span>已选</span>
      <div class="container">
        <div class="user-address">
          <nut-icon name="location2" color="#f2270c" size="12px"></nut-icon>
          <nut-ellipsis :content="text" direction="end"></nut-ellipsis>
        </div>
        <div class="express">
          <span class="red_text">现货</span>
          <span>23:00前下单，预计<b>后天</b> 送达</span>
        </div>
        <div class="service">
          <ul>
            <li class="detail_serve_item ">
              安一发货&售后
            </li>
            <li class="detail_serve_item ">
              支持7天无理由退货
            </li>
            <li class="detail_serve_item ">
              不参与秒杀满减和赠品等优惠活动
            </li>
          </ul>
        </div>
      </div>
      <nut-icon name="more-x"></nut-icon>
    </div>
    <!-- 使用底部弹出,弹出姓名,电话,地址输入框,输入地址后保存展示 -->
    <nut-popup position="bottom" :style="{ height: '50%' }" v-model:visible="showBottom">
      <view class="popup_content">
        <view class="popup_title">新增地址</view>
        <nut-input placeholder="请输入姓名" v-model="name"></nut-input>
        <nut-input placeholder="请输入手机号" v-model="phone" type="tel"></nut-input>
        <nut-input placeholder="请输入详细地址" v-model="address"></nut-input>
        <view class="popup_btn">
          <nut-button type="primary" size="small" @click="close">取消</nut-button>
          <nut-button type="warning" size="small" @click="saveAddress">保存</nut-button>
        </view>
      </view>
    </nut-popup>
  </div>
</template>

<script>
export default {
  name: 'AddressInfo',
  data() {
    return {
      showPopupExist: false,
      showBottom: false,
      name: '',
      phone: '',
      address: '广东省肇庆市鼎湖区',
      text: '广东省肇庆市鼎湖新区砚阳湖公园1街1室',
    };
  },
  methods: {
    showAddressExist() {
      this.showBottom = true;
    },
    close() {
      this.showBottom = false;
    },
    saveAddress() {
      this.text = this.name + ' ' + this.phone + ' ' + this.address;
      this.showBottom = false;
    },
  },
}
</script>

<style lang="scss">
.address_info {
  background-color: #fff;
  border-radius: 12px;
  margin-top: 12px;
  padding: 24px 18px;

  .selected_info {
    display: flex;
    font-size: 13px;
    color: #333;

    span {
      width: 38px;
      font-weight: 700;
      flex-shrink: 0;
    }

    .container {
      flex: 1;

      .user-address {
        display: flex;
        align-items: center;
        flex: 1;
        line-height: 20px;
      }

      .nut-ellipsis {
        flex: 1;
        margin-left: 4px;
      }

      .express {
        .red_text {
          color: #f2270c;
          margin-right: 3px;
        }

        span {
          font: inherit;
          font-size: 13px;
          color: #999;

          b {
            font: inherit;
          }
        }
      }

      .service {
        padding-top: 15px;
        padding-right: 30px;

        .detail_serve_item {
          color: #8c8c8c;
          display: inline-block;
          line-height: 1;
          padding: 3px 12px 3px 0;

          &::before {
            background: url(//jstatic.3.cn/static/icon_can_choose_jd.dba11276.png?__inline) no-repeat;
            background-size: 11px auto;
            content: "";
            display: inline-block;
            height: 11px;
            margin-right: 6px;
            margin-top: -2px;
            vertical-align: middle;
            width: 11px;
          }
        }
      }
    }
  }
}

.popup_content {

  .popup_title {
    font-size: 16px;
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .popup_btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}</style>