<template>
  <view id="moreDetail" class="anchor"></view>
  <view class="more_detail">
    <view class="detail_title">商品详情</view>
    <!-- 遍历文字和图片 -->
    <view class="tab_wrap">
      <view class="tab_item" v-for="(item, index) in tabArr" :key="index">
        <img :src="item.url" alt="">
      </view>
    </view>
  </view>
</template>

<script>
export default {
  name: 'MoreDetail',
  data() {
    return {
      tab1value: 0,
      tabArr: [
        {
          title: '商品详情',
          url: 'https://img.starlake.tech/pic/23c1bbe6ee0c6aa6fea1abfd512d9d63.jpg',
        }
      ],
    };
  },
}
</script>

<style lang="scss">
.more_detail {
  padding: 0 15px;
  .tab_wrap {
    margin-top: 10px;
    .tab_item {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.detail_title{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  // 居中
  text-align: center;
  padding-right: 10px;
}

.anchor {
  width: 100%;
  height: 1px;
  background-color: #fff;
}


</style>