<template>
  <div class="goods_sku">
    <div class="selected_info" @click="showSku">
      <span>已选</span>
      <p>全年套餐 【豪华版】-赠300万元火灾公众责任险 1件</p>
      <nut-icon name="more-x"></nut-icon>
    </div>
    <nut-sku v-model:visible="flag" :sku="sku" :goods="goods" @selectSku="selectSku"
      @clickBtnOperate="clickBtnOperate"></nut-sku>
  </div>
</template>

<script>
export default {
  name: 'GoodsSku',
  data() {
    return {
      flag: false,
      sku: [
        {
          id: 1,
          name: '套餐',
          list: [
            {
              id: 1,
              name: '全年套餐 【豪华版】-赠300万元火灾公众责任险',
              active: true,
              disable: false
            }
          ]
        },
        {
          name: '颜色',
          list: [
            {
              id: 1,
              name: '红色',
              active: true,
              disable: false
            }
          ]
        },
      ],
      goods: {
        skuId: 1,
        price: 360,
        stock: 100,
        imagePath: 'https://img.starlake.tech/pic/98c54619824734224115b9cb7ac386c7.png',
        imagePathMap: {
          1: 'https://img.starlake.tech/pic/98c54619824734224115b9cb7ac386c7.png',
        }
      }
    }
  },
  methods: {
    showSku() {
      this.flag = true;
    },
    selectSku(sku, skuIndex, parentSku, parentIndex) {
      console.log(sku, skuIndex, parentSku, parentIndex);
      if (sku.disable) return false
      this.sku[parentIndex].list.forEach(item => {
        item.active = item.id === sku.id
      })
    },
    clickBtnOperate(type, sku) {
      if (type === 'addCart') {
        console.log('加入购物车', sku)
      } else if (type === 'buy') {
        console.log('立即购买', sku)
      }
    },
  },
}

</script>

<style lang="scss">
.goods_sku {
  background-color: #fff;
  border-radius: 12px;

  .selected_info {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    font-size: 13px;
    color: #333;

    span {
      width: 38px;
      font-weight: 700;
      flex-shrink: 0;
    }

    p {
      flex: 1;
    }
  }
}
</style>