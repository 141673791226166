<template>
  <view class="goods_info" >
    <view id="goodsInfo" ></view>
    <nut-swiper
      pagination-color="#426543"
      auto-play="0"
      :loop="true"
      @change="change"
      height="375"
    >
      <nut-swiper-item v-for="(banner, index) in banners" :key="index">
        <img class="item_img" :src="banner" alt=""/>
      </nut-swiper-item>
      <template v-slot:page>
        <div class="page">{{ current }}/{{ banners.length }}</div>
      </template>
    </nut-swiper>
    <view class="price_wrap">
      <nut-price
        :price="360"
        :need-symbol="true"
        size="large"
        :thousands="false"
      />
      <view class="opt_wrap">
        <view class="btn">
          <nut-icon name="s-follow"></nut-icon>
          <view>收藏</view>
        </view>
        <view class="btn">
          <nut-icon name="share"></nut-icon>
          <view>分享</view>
        </view>
      </view>
    </view>
    <view class="text_container">
      <view class="goods_name">
        地址关联式烟感套餐 独立式联网烟感探测器
        软件平台及人工双值守
      </view>
      <view class="goods_attr"> <p>商品编号：1</p></view>
    </view>
  </view>
</template>

<script>
// import { application } from 'express';


export default {
  name: 'GoodsInfo',
  data() {
    return {
      current: 1,
      banners: [
        'https://img.starlake.tech/pic/98c54619824734224115b9cb7ac386c7.png',
        'https://img.starlake.tech/pic/2d4bb170d710f8a8751c2bf6d9677a39.png',
        'https://img.starlake.tech/pic/1ff8de9cd4421e38bba730288281cf14.png',
        '../../assets/all.jpg',
      ],
      imgUrlHeader: '',
    };
  },
  methods: {
    change(index) {
      this.current = index + 1;
    },
  },
}
</script>

<style lang="scss">
.goods_info {
  width: 100%;
  background: #fff;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 0 0 0 #ececee;
  .page {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 46px;
    height: 22px;
    background: rgba(0, 0, 0, 0.33);
    border-radius: 22px 0 0 22px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
  }
  .item_img {
    width: 100%;
    height: 100%;
  }
  .price_wrap {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0px 12px;
    // background-image: url(//storage.jd.com/jdcdkh/welfare/5.5.91-43/img/detail-bg.png);
    background-size: 100%;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .nut-price {
      color: #df3d3d;
    }
    .opt_wrap {
      display: flex;
      align-items: center;
      .btn {
        margin: 0 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        height: 100%;
        line-height: 12px;
      }
    }
  }
  .text_container {
    padding: 12px 18px 24px 18px;
    .goods_name {
      font-size: 18px;
      word-break: break-all;
      line-height: 24px;
      font-weight: 800;
    }
    .goods_attr {
      padding-top: 12px;
      p {
        font-size: 12px;
        color: #999;
      }
    }
  }
}
</style>